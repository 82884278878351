<template>
    <div class="loginIndex">
        <div class="image_div">
            <img src="./../../assets/img/logo.png">
        </div>
        <div class="content" :class="istrue==1?'s--signup':''">
            <!-- 登录窗口 -->
            <div class="form sign-in" id="login_div" v-show="istrue==0&&hide==true">
                <h2 style="margin-bottom:80px;">欢迎回来</h2>
                <label>
                    <span>手机号</span>
                    <input id="mobile" type="text" v-model="d_phone" @focus="d_login">
                </label>
                <label>
                    <span>密码</span>
                    <input id="passwd" type="password" v-model="d_pass" @focus="d_login">
                </label>
                <button id="login" type="button" class="submit btn" @click="logins()">登 录</button>
                <p class="forgot-pass"><a href="javascript:" @click="forget(false)">忘记密码？</a></p>
                <div id="login-msg" class="index-msg">
                    <center>
                        <span style="margin-top: 5px; padding-left: 5px; color: red; font-size: 16px; word-break: break-all;">{{d_msg}}</span>
                    </center>
                </div>
            </div>
            <!-- 忘记密码窗口 -->
            <div id="resetpwd_div" class="form sign-in" v-show="istrue==0&&hide==false">
                <h2 style="margin-bottom:30px;">密码修改</h2>
                <label>
                    <span>手机号</span>
                    <input id="mobile2" type="text" v-model="w_phone" @focus="d_login">
                </label>
                <label>
                    <span>验证码</span>
                    <span style="display:block;">
                        <input id="captcha2" type="text" style="width:60%; float:left;" v-model="w_code" @focus="d_login">
                        <button type="button" class="fb-btn btn2" style="width:40%; float:left; height:30px; font-size:14px;" :disabled="disableds" @click="obtain()">{{w_codes}}</button>
                    </span>
                </label>
                <label style="margin-top:50px;">
                    <span>新密码</span>
                    <input id="passwd2" type="password" v-model="w_pass" @focus="d_login">
                </label>
                <button id="regist2" type="button" class="submit btn" @click="submits()">提交</button>
                <p class="relogin"><a href="javascript:" @click="forget(true)">返回登录</a></p>
                <div id="resetpwd-msg" class="index-msg">
                    <span style="margin-top: 5px; padding-left: 5px; color: red; font-size: 16px; word-break: break-all;">{{w_msg}}</span>
                </div>
            </div>
            <div class="sub-cont">
                <div class="img">
                    <div class="img__text m--up">
                        <h2>还未注册？</h2>
                        <p>立即注册，开启创赛新篇章！</p>
                    </div>
                    <div class="img__text m--in">
                        <h2>已有帐号？</h2>
                        <p>点击登录，体验高效创赛服务！</p>
                    </div>
                    <div class="img__btn" @click="region()">
                        <span id="r_regiest" class="m--up">注&nbsp;&nbsp;&nbsp;&nbsp;册</span>
                        <span id="r_login" class="m--in">登&nbsp;&nbsp;&nbsp;&nbsp;录</span>
                        <div class="img__btn">
                            <span id="r_regiest" class="m--up">注&nbsp;&nbsp;&nbsp;&nbsp;册</span>
                            <span id="r_login" class="m--in">登&nbsp;&nbsp;&nbsp;&nbsp;录</span>
                        </div>
                    </div>
                </div>
                <!-- 注册窗口 -->
                <div class="form sign-up">
                    <h2 style="margin-bottom:60px;">立即注册</h2>
                    <label>
                        <span>手机号</span>
                        <input id="mobile3" type="text" v-model="r_phone" @focus="d_login">
                    </label>
                    <label>
                        <span>验证码</span>
                        <span style="display:block;">
                            <input id="captcha" type="text" style="width:60%; float:left;" v-model="r_code" @focus="d_login">
                            <button type="button" class="fb-btn btn2" style="width:40%; float:left; height:30px; font-size:14px;" :disabled='disabled' @click="obtain1()">{{r_codes}}</button>
                        </span>
                    </label>
                    <label style="margin-top:50px;">
                        <span>登录密码</span>
                        <input id="passwd3" type="password" v-model="r_pass" @focus="d_login">
                    </label>
                    <button id="regist" type="button" class="submit btn" @click="regists()">注&nbsp;&nbsp;&nbsp;&nbsp;册</button>
                    <div id="resetpwd-msg" class="index-msg">
                        <span style="margin-top: 5px; padding-left: 5px; color: red; font-size: 16px; word-break: break-all;">{{r_msg}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer">
            <!-- <span style="font-family:arial;">为保证系统正常运行和良好的体验，建议使用
                <a target="_blank" href="https://download-ssl.firefox.com.cn/releases-sha2/stub/official/zh-CN/Firefox-latest.exe">火狐浏览器</a>、
                <a target="_blank" href="https://dl.softmgr.qq.com/original/Browser/83.0.4103.106_chrome_installer_32.exe">chrome浏览器</a>、
                <a target="_blank" href="https://dl.liebao.cn/kb/KSbrowser_home_201804101.exe">猎豹浏览器</a>访问</span><br> -->
            <span style="font-family:arial;">Copyright © 创项课 2022</span>
            <p>
                <!-- <img src="https://wsbs.shxga.gov.cn/webjjcluster/images/jinghui.ico" alt="" style="width:19px;margin-top:-4px;vertical-align: middle;"> &nbsp;&nbsp;&nbsp; -->
                <a href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=61019002001772" target="blank">
                    <span style="margin-right:15px">陕西重构教育科技有限公司</span>
                </a>
                <!-- <a href="https://beian.miit.gov.cn/" target="blank">陕ICP备2021008899号-3</a> -->
            </p>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            d_msg:'',
            w_msg:'',
            r_msg:'',
            istrue:0,
            hide:true,
            d_phone:'',
            d_pass:'',
            w_code:'',
            w_phone:'',
            w_pass:'',
            r_code:'',
            r_phone:'',
            r_pass:'',
            w_codes:'获取验证码',
            r_codes:'获取验证码',
            disabled:false,
            disableds:false,
        }
    },
    methods: {
        // 登录
        logins(){
            if(!(this.d_phone)){
                this.d_msg='请输入手机号'
            }else if(!/^1[3456789]\d{9}$/.test(this.d_phone)){
                this.d_msg='请输入正确的手机号'
            }else if(!this.d_pass){
                this.d_msg='请输入密码'
            }else{
                this.axios.userlogin({
                    mobile:this.d_phone,
                    passwd:this.d_pass
                }).then(res=>{
                    if(res.data.code==0){
                        this.$store.dispatch("phone",res.data.data.user_mobile)
                        this.$store.dispatch("token",res.data.data.token)
                        this.$store.dispatch("user_avatar",res.data.data.user_avatar)
                        this.$store.dispatch("user_name",res.data.data.user_name)
                        if(res.data.data.user_type==1){
                            this.$router.push({name:'student'})
                        }else{
                            this.$router.push({name:'teacher'})
                        }
                    }else{
                        this.d_msg = res.data.message
                    }
                }).catch(err=>{

                })
            }
        },
        // 忘记密码提交
        submits(){
            if(!this.w_phone){
                this.w_msg='请输入手机号'
            }else if(!/^1[3456789]\d{9}$/.test(this.w_phone)){
                this.w_msg='请输入正确的手机号'
            }else if(!this.w_code){
                this.w_msg='请输入验证码'
            }else if(!this.w_pass){
                this.w_msg='请输入密码'
            }else{
                this.axios.userresetpwd({
                    mobile:this.w_phone,
                    captcha:this.w_code,
                    passwd:this.w_pass
                }).then(res=>{
                    if(res.data.code==0){
                        this.$message.success('密码修改成功,请登录')
                        this.hide = true
                    }else{
                        this.w_msg = res.data.message
                    }
                }).catch(err=>{

                })
            }
        },
        //注册
        regists(){
            if(!this.r_phone){
                this.r_msg='请输入手机号'
            }else if(!/^1[3456789]\d{9}$/.test(this.r_phone)){
                this.r_msg='请输入正确的手机号'
            }else if(!this.r_code){
                this.r_msg='请输入验证码'
            }else if(!this.r_pass){
                this.r_msg='请输入密码'
            }else{
                this.axios.userregist({
                    mobile:this.r_phone,
                    captcha:this.r_code,
                    passwd:this.r_pass
                }).then(res=>{
                    if(res.data.code==0){
                        this.$message.success('注册成功,请登录')
                        this.istrue = 0
                    }else{
                        this.r_msg = res.data.message
                    }
                }).catch(err=>{

                })
            }
        },
        // 忘记密码获取验证码
        obtain(){
            if(this.disableds == false){
                if(!this.w_phone){
                    this.w_msg='请输入手机号'
                }else if(!/^1[3456789]\d{9}$/.test(this.w_phone)){
                    this.w_msg='请输入正确的手机号'
                }else{
                    this.axios.usersmsresetpwd({
                        mobile:this.w_phone
                    }).then(res=>{
                        if(res.data.code==0){
                            this.times = 60
                            this.timers()
                        }else{
                            this.w_msg = res.data.message
                        }
                    }).catch(err=>{
                        
                    })
                    
                }
            }else{

            }
            
        },
        //注册账号获取验证码
        obtain1(){
            if(this.disabled==false){
                if(!this.r_phone){
                    this.r_msg='请输入手机号'
                }else if(!/^1[3456789]\d{9}$/.test(this.r_phone)){
                    this.r_msg='请输入正确的手机号'
                }else{
                    this.axios.usersmsregist({
                        mobile:this.r_phone
                    }).then(res=>{
                        if(res.data.code==0){
                            this.time = 60
                            this.timer()
                        }else{
                            this.r_msg = res.data.message
                        }
                    }).catch(err=>{

                    })
                    
                }
            }
        },
        timer(){
            if(this.time>0){
                this.disabled = true
                this.time--
                this.r_codes = ""+this.time+"s"
                setTimeout(this.timer,1000)
            }else{
                this.time = 0
                this.r_codes = "重新发送"
                this.disabled = false
            }
        },
        timers(){
            if(this.times>0){
                this.disableds = true
                this.times--
                this.w_codes = ""+this.times+"s"
                setTimeout(this.timers,1000)
            }else{
                this.times = 0
                this.w_codes = "重新发送"
                this.disableds = false
            }
        },
        // 获取焦点
        d_login(){
            this.d_msg = ''
            this.w_msg = ''
            this.r_msg = ''
        },
        //注册登录切换
        region(){
            if(this.istrue==1){
                this.istrue = 0
            }else{
                this.istrue=1
            }
        },
        // 忘记密码切换
        forget(val){
            this.hide=val
        },
    },
}
</script>
<style scoped>
@import './../../assets/css/login/login.css';
</style>